import styled from "styled-components"
import customisationForTheme from "mill/utils/customisations"
import { sm, md } from "mill/utils/breakpoints"

const imageUrl = customisationForTheme("illustrations.onboardingSignup")
const background =
  window.THEME_NAME != "yarno" ? `${imageUrl}&sat=-100` : imageUrl

export const Background = styled.div`
  max-width: 100rem;
  width: 100%;
  margin: 0 auto;

  ${props =>
    props.ssoEnabled &&
    `
    @media ${sm} {
      width: 90%;
      background: url(${background});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
    }
  `}

  @media ${md} {
    width: auto;
    min-width: 75rem;
    background: url(${background});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 60rem;
  margin: 0 auto;
  padding: 2rem 0;

  input {
    width: 100%;
  }

  h1,
  h2 {
    color: ${props => props.theme.colors.primary};
  }
`

export const H1 = styled.h1`
  font-size: 3.2rem;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
`

export const H2 = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-top: 0;
  margin-bottom: 2rem;
`

export const Fieldset = styled.div`
  background-color: white;

  @media ${sm} {
    padding: 1rem 2rem 3rem 2rem;
    max-width: 62rem;
    margin: 0 0.5rem;
    box-shadow: 0 0 28px 6px rgba(69, 69, 69, 0.08);
    border-radius: 0.6rem;
    border-radius: 3px;
  }

  @media ${md} {
    padding: 1rem 6rem 3rem 6rem;
    margin: 0 1rem;
  }
`
